.hero {
	&.hero--aside {
		padding-bottom: 12rem;
	}
	.hero__background-image {
		max-height: 50rem;
		overflow: hidden;
		img {
			width: 100%;
		}
	}
	.hero__aside-image {
		float: left;
		width: 40%;
		img {
			width: 100%;
		}
	}
	.hero__content {
		h1 {
			margin-top: 0;
			margin-bottom: 1rem;
			line-height: 1;
		}
		.hero__byline {
			font-family: "Noto Sans";
			font-size: 2rem;
			margin-top: 0;
			line-height: 1;
			margin-bottom: 3rem;
		}
		float: left;
		width: 60%;
		padding: 0 4rem;
		.button {
			margin-top: 2rem;
		}
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.hero {
		.hero__aside-image {
			float: none;
			width: 100%;
			padding: 4rem;
			padding-bottom: 2rem;
		}
		.hero__content {
			float: none;
			width: 100%;
			.button {
				float: none;
				margin-top: 1rem;
				margin-left: 0;
			}
		}
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
