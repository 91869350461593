// **********
// TYPOGRAPHY
// **********
$serif: "Neuton",serif;
$sans: "Noto Sans",sans-serif;
// $condensed: "Oswald", sans-serif;
$icons:FontAwesome;

@mixin uppercase{
	text-transform: uppercase;
};

@mixin serif-italic{
	font-family: $serif;
    font-style: italic;
}

@mixin sans-light{
	font-family: $sans;
	font-weight: 400;
}

@mixin sans-uppercase-normal{
	font-family: $sans;
	text-transform: uppercase;
	font-weight: 700;
}

@mixin absolute--center{
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
}

// **********
// COLORS
// **********
// Base Colors
$white: #FFFFFF;
$off-white: #F3F3F3;
$black: #000000;
$white-translucent: rgba(255,255,255,0.3);
$black-translucent: rgba(0,0,0,0.6);

// Text colors
$text_slate:#374049;

// Border
$border-grey: #CCCCCC;

// CDA Colors
$cda-grey: #dfdbd8;
$cda-dark-grey: #717171;
$cda-pale-blue: #d7e9f6;
$cda-medium-blue: #1c89ca;
$cda_blue: #1865B1;
$cda-dark-blue: #183561;
$cda-red: #c12026;
$cda-off-red:#d45a5e;
$cda-button--white: #F2F2F2;
$cda-orange: #FE9F00;

// Hover
$cda-red--hover:#e3495e;
$cda-blue--hover:#20A3E2;

// Social Colors
$facebookBlue: #3b5998;
$twitterBlue: #4099ff;

// Box Shadows
$box-shadow--light: 0 0 8px rgba(0,0,0,.2);

// Padding/Margins
$content-padding:3.6rem;
$p-spacing: 2rem;

// Some math to get container size then base blocks off them
// Its half the container size at each media query
$container-sm-half:37.5rem;
$container-md-half:48.5rem;
$container-lg-half:58.5rem;

$container-sm:75rem;
$container-md:97rem;
$container-lg:117rem;

// Animations
$transition--opacity: opacity 300ms linear;
$transition--all: all 300ms ease-in-out;
$transition--all-fast: all 100ms;
$transition--slider: all 400ms ease-in-out;

$timing: 300ms;
$easing: ease-in-out;

