.news__section{
	margin-top: 4rem;
	a{
		color: $text-slate;
		transition: all .2s ease-in-out;
		// border-bottom: 0.1em solid transparent;
		box-shadow: inset 0px -0.1em 0px 0px transparent;
		text-decoration: none;
		&:hover,
		&:focus {
			color: $cda-blue--hover;
			box-shadow: inset 0px -0.1em 0px 0px $cda-blue--hover;
			text-decoration: none;
		}
	}
	h3{
		margin-top: 0;
		font-size: 2rem;
		font-weight: bold;
		font-family: $sans;
	}
}
.news__subhead{
	color: $cda-red;
}
.news__heading{
	margin-top: 0;
}
.news__date{
	color: $cda-dark-grey;
}
.news_image{
	width: 100%;
	margin-bottom: 2rem;
}
/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {

}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {

}