html,
body {
	font-size: 10px;
}

body {
	font-size: 1.6rem;
	a{-webkit-font-smoothing: subpixel-antialiased;}

}


body.body--freeze {
	overflow: hidden;
}

// JavaScript Created Element Styles
// BLazy
.b-lazy {
	opacity: 0;
}
.slide__img {
		opacity: 0;
	}
.b-loaded {
	opacity: 1;
	transition: opacity 200ms;
}

// Screen
.screen__overlay {
	position: fixed;
	top: 12rem;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	display: none;
	&.screen__overlay--on {
		display: block;
	}
}

// Back to Top
.back-to-top {
	position: fixed;
	right: 0;
	width: 10rem;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: $transition--opacity;
	i.fa {
		font-size: 3rem;
	}
	span {
		display: block;
		position: relative;
		font-size: 1.2rem;
		&:before {
			font-size: 4.5rem;
			height: 3rem;
			width: 10rem;
			@include icon('icon-page-top-arrow');
			position: absolute;
			text-align: center;
			left: 0;
			top: -5rem;
		}
		&::selection {
			background-color: transparent;
		}
	}
	&.back-to-top--scrolled {
		bottom: 8vh;
		cursor: pointer;
		visibility: visible;
		opacity: .5;
		transition: $transition--opacity;
		&:hover {
			opacity: 1;
			transition: $transition--opacity;
		}
	}
}
a{
	color: $cda-blue;
	-webkit-backface-visibility: hidden;
	&:hover, &:focus{
		color:$cda-blue--hover;
	}
}
// Buttons
button,
input {
	border: none;
	background-color: transparent;
	border-radius: 0;
}
.container--narrow{
	max-width: 99rem;
}
.button {
	display: inline-block;
	cursor: pointer;
	text-align: center;
	color: $white;
	font-family: $sans;
	font-size: 1.4rem;
	text-transform: uppercase;
	padding: 1.4rem 3rem;
	text-decoration: none;
	max-width: 22rem;
	transition: $transition--all;
	&.button--small {
		padding: 1rem 2rem;
		font-size: 1.2rem;
	}
}

.button--red {
	background-color: $cda-red;
	&:hover, &:focus {
		background-color: $cda-orange;
		color: $white;
		text-decoration: none;
	}
}

.button--white {
	background-color: $cda-button--white;
	color: $text-slate;
	&:hover, &:focus {
		background-color: $white;
		color: $cda-red;
		text-decoration: none;
	}
}

.button--clear {
	border: 1px solid $cda-button--white;
	background-color: transparent;
	color: $cda-button--white;
	&:hover, &:focus {
		border-color: $white;
		background-color: $white;
		color: $text-slate;
		text-decoration: none;
	}
}

.button--grey {
	background-color: $cda-grey;
	color: $text-slate;
	&:hover, &:focus {
		background-color: $off-white;
		color: $text-slate;
		text-decoration: none;
	}
}

.button--blue {
	background-color: $cda-blue;
	&:hover, &:focus {
		background-color: $cda-blue--hover;
		color: $white;
		text-decoration: none;
	}
}

.button--center{
	margin: auto;
	display: block;
}

.button--right{
	float: right;
	margin-left: 1rem;
}
.button:active {
	position: relative;
	top: 1px;
}

.pill {
	display: inline-block;
	text-align: center;
	font-family: $sans;
	text-transform: uppercase;
	text-decoration: none;
	max-width: 22rem;
	transition: $transition--all;
	padding: 1rem 2rem;
	margin: 1rem;
	font-size: 1.2rem;
	background-color: $cda-grey;
	color: $text-slate;
	float: right;
}
// Links
.link__arrow {
	position: relative;
	&:after {
		@include icon('icon-arrow-right-circle');
		width: 2rem;
		position: absolute;
		right: -2.6rem;

	}
}

// Main Content
.main {
	margin-top: 14.8rem;
}
.main ul:not([class]) {
    padding-left: 3rem;
}
.content-block--secondary{
	h3{
		color: $text-slate;
	}
	.share__social{
		float: left;
	}
}

.content-block__header {
	text-align: center;
}

.content-block__section, .article__section{
	max-width: 99rem;
	margin: auto;
}
.iframe--full{
	width: 100%;
	min-height: 60rem;
}
.article {
	padding-top: 2rem;
	padding-bottom: 4rem;
	ol:not([class]),ul:not([class]){
		padding-left: 4rem;
		padding-bottom: 1rem;
	}
	ul:not([class]) > li,
	ol:not([class]) > li{
		list-style-type: disc;
		list-style-position: inside;
		text-indent: -33px;
		padding-left: 4px;
	}
}

.article__header {
	text-align: center;
	max-width: 64rem;
	margin: auto;
}

.article--red {
	background-color: $cda-red;
	color: $white;
	.article__header {
		color: $white;
	}
}

.article--blue {
	background-color: $cda-blue;
	color: $white;
	.article__header {
		color: $white;
	}
}

.article--grey, .background--grey {
	background-color: $off-white;
}

// Flexbox
.flex__column {
	display: flex;
	align-items: center;
	min-height: 32rem;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	.flex--v-center {
		flex: none;
	}
}

// Images and Videos
// Embeds responsive
//
// Credit: Nicolas Gallagher and SUIT CSS.
.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;
	margin-top: 1rem;
	margin-bottom: 1rem;
	cursor: pointer;
	background-color: $black;
	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}
	.embed__image {
		width: 100%;
		opacity: 0.8;
	}
	&.embed-responsive--play:after {
		@include icon('icon-video-player-icon');
		font-size: 5rem;
		height: 6rem;
		display: block;
		width: 6rem;
		position: absolute;
		top: 50%;
		margin-top: -3rem;
		left: 50%;
		margin-left: -3rem;
		color: white;
	}
}

// Modifier class for 16:9 aspect ratio
.embed-responsive-16by9 {
	padding-bottom: 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
	padding-bottom: 75%;
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	// Main Content
	.main {
		margin-top: 14.2rem;
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
