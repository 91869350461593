.card__row {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.card {
	margin-bottom: 4rem;
	position: relative;
	background-color: $white;
	border: 1px solid $border-grey;
	border-top: 3px solid $cda-red;
	box-shadow: $box-shadow--light;
	margin: 2rem 1rem;
	transition: all 300ms ease-in-out;
	padding: 0;
	&.card--red {
		background-color: $cda-red;
		color: $white;
		.card-block .card-block__content h3 {
			color: $white;
		}
	}
	&.card--no-button .card-block__content {
		margin-bottom: 2rem;
	}
}

a.card {
	color: inherit;
	text-decoration: none;
	h3 {
		position: relative;
		color: $text-slate;
		transition: all .2s ease-in-out;
		text-decoration: none;
	}
}

.card-block {
	.card-block__image {
		width: calc(100% + 3rem);
		margin-left: -1.5rem;
		margin-right: -1.5rem
	}
	.card-block__content {
		h3 {
			font-size: 2.6rem;
			margin: 1.2rem 0;
			color: $text-slate;
		}
		padding: 1rem;
		padding-bottom: 0;
		margin-bottom: 8rem;
	}
	.card-block--no-footer {
		margin-bottom: 8rem;
	}
}

.card__footer {
	position: absolute;
	@include absolute--center;
	bottom: 2rem;
	.button {
		display: block;
		margin: auto;
		max-width: 18rem;
	}
}

// Special Card Size for Home
.flexbox .card.col-sm-3 {
	flex-basis: calc(25% - 2rem);
	max-width: calc(25% - 2rem);
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.card {
		margin-bottom: 4rem;
	}
	.flexbox .card.col-sm-3 {
		flex-basis: calc(100% - 2rem);
		max-width: calc(100% - 2rem);
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {
	.card{
		&:hover,
		&:focus {
			transform: scale(1.03);
			transition: all 300ms ease-in-out;
			backface-visibility: hidden;
			filter: blur(0px);
			filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
			filter: none;
			h3 {
				color: $cda-blue--hover;
				transition: $transition--all;
				text-decoration: underline;
			}
		}
	}
}
