$form-text--focus: #fff;

// Warning!
// Need to use Ektron Form...
// The will be ids and !importants
// Forgiviness is a virtue
.form__fieldset{
	line-height: 1.6;
	margin-top: 3rem;
	.button{
		display: block;
	}
	
	#design_content, div.design_content {
		overflow: auto;
		padding: none;
		background-image: none;
		border-style: none;
		background-color: transparent;
	}
	input.input__field {
		position: relative;
		margin: 0;
		margin-top: 1rem;
		max-width: 40rem;
		width: calc(100% - 2rem);
		vertical-align: top;		
		display: block;
		border-radius: 0;
		padding: 1rem;
		width: 100%;
		&:focus {
			outline: none;
		}
	}
}
.article--red{
	.input__field{
		background: $cda-off-red;
		color: $white;
		border: 1px solid $white-translucent;
	}
}
.input {
	position: relative;
	display: inline-block;
	margin: 0;
	max-width: 40rem;
	width: calc(100% - 2rem);
	vertical-align: top;
}

.input__field {
	position: relative;
	display: block;
	border: none;
	border-radius: 0;
	z-index: 0;
	padding: 1rem;
	width: 100%;
	border: 1px solid #ddd;
}

.input__field:focus {
	outline: none;
}

.input__label {
	font-size: 1.4rem;
	padding: 1rem 0;
	user-select: none;
}

.input__label-content {
	position: relative;
	display: block;
	padding: 1.6rem 0;
	width: 100%;
}
.form--login.form__fieldset {
	width: 100%;
	margin: 0 auto;
	max-width: 38rem;
	padding: 0 2rem;
	label{
		padding-top: 1rem;
	}
	.form__submit{
		// 
		@include clearfix;
	}
	.form__remember{
		line-height: 1;
		margin-bottom: 2rem;
	}
	.button.form__cancel{
		width: calc(50% - 1rem);
		float: left;
		text-align: center;
		margin-right: 1rem;
	}
	.form__error{
		color: $cda-red;
	}
    .input__field{
		width: 100%;
		background-color: $white;
		&.input--error{
			border-color: $cda-off-red;
		}
    }
    .button{
    	margin-bottom: 1rem;
    	float: right;
    	width: 50%;
    }
    .error-box{
    	margin-top: 0;
    	margin-bottom: 1rem;
    	color: $cda-red;
    }
}
.error-box{
	margin-top: 1rem;
	max-width: 40rem;
	min-height: 3rem;
    opacity: 0;
    display: block;
    transition: $transition--all;
    &.error-box--display{
    	opacity: 1;
    	transition: $transition--all;
    }
}
/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
