.staff__card {
	color: $text-slate;
	text-align: center;
	padding: 2rem;
	padding-top: 0;
	> a{
		display: block;
		@include clearfix;
		h3 {
			position: relative;
			display: inline;
			&:before {
				content: "";
				position: absolute;
				display: inline;
				width: 100%;
				height: 2px;
				bottom: 0;
				left: 0;
				background-color: $cda-blue--hover;
				opacity: 0;
				transition: $transition--all;
			}
		}
		&:hover, &:focus {
			text-decoration: none;
			transition: $transition--all;
			background-color: $white;
			border-color: $cda-grey;
			h3 {
				color: $cda-blue--hover;
				transition: $transition--all;
				&:before {
					opacity: 1;
					transition: $transition--all;
				}
			}
			p{
				color: $cda-blue;
			}
		}
	}
	img {
		border-radius: 100%;
		display: block;
		margin: 1rem auto;
		max-width: 20rem;
	}
	h3 {
		margin-top: 1rem;
		color: $text-slate;
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
