.gallery__row{
	@include clearfix;
}
.gallery{
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
    .gallery__set{
    	position: relative;
		width: 100%;
		height: 0;
		background-size: cover;
		padding-bottom: 60%;
		cursor: pointer;
		display: block;
    	&:after{
			content: "VIEW ALL";
			top: 50%;
			left: 50%;
			margin-top: -3rem;
			margin-left: -3rem;
			padding: 1rem;
			width: 6rem;
			height: 6rem;
			z-index: 0;
			border-radius: 100%;
			transition: opacity .5s ease;
			color: $white;
			position: absolute;
			border:1px solid $white;
			text-align: center;
			font-size: 1.2rem;
			pointer-events:none;
			opacity: 0;
		}
		&:before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			pointer-events:none;
			height: 100%;
			z-index: 0;
			transition: opacity .5s ease;
			background-color: $black-translucent;
			opacity: 0;
		}
		&:hover{
			&:after{
				transition: opacity .5s ease;
				opacity: 1;
			}
			&:before{
				transition: opacity .5s ease;
				opacity: 1;
			}
		}
	}
	.gallery__item{
		display: none;
		&:first-child{
			display: block;
		}
		img{
			width: 100%;
			height: 0;
			display: block;
			padding-bottom: 60%;
		}
	}
	.gallery__caption h3{
		color: $text-slate;
		margin-top: 1rem;
	}
}


/*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.8.0
 * @url https://github.com/feimosi/baguetteBox.js
 */

#baguetteBox-overlay {
	display: none;
	opacity: 0;
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	background-color: rgba(0, 0, 0, 0.8);
	transition: opacity .5s ease;
}

#baguetteBox-overlay.visible {
	opacity: 1;
}

#baguetteBox-overlay .full-image {
	display: inline-block;
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
}

#baguetteBox-overlay .full-image figure {
	display: inline;
	margin: 0;
	height: 100%;
}

#baguetteBox-overlay .full-image img {
	display: inline-block;
	width: auto;
	height: auto;
	max-height: 100%;
	max-width: 100%;
	vertical-align: middle;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

#baguetteBox-overlay .full-image figcaption {
	display: block;
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	line-height: 1.8;
	white-space: normal;
	color: #ccc;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.6);
	font-family: sans-serif;
}

#baguetteBox-overlay .full-image:before {
	content: "";
	display: inline-block;
	height: 50%;
	width: 1px;
	margin-right: -1px;
}

#baguetteBox-slider {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	white-space: nowrap;		
	transition: left .4s ease, transform .4s ease;	
}

#baguetteBox-slider.bounce-from-right {
	animation: bounceFromRight .4s ease-out;
}

#baguetteBox-slider.bounce-from-left {
	animation: bounceFromLeft .4s ease-out;
}

@keyframes bounceFromRight {
	0% {
		margin-left: 0;
	}
	50% {
		margin-left: -30px;
	}
	100% {
		margin-left: 0;
	}
}

@keyframes bounceFromLeft {
	0% {
		margin-left: 0;
	}
	50% {
		margin-left: 30px;
	}
	100% {
		margin-left: 0;
	}
}

.baguetteBox-button#next-button,
.baguetteBox-button#previous-button {
	top: 50%;
	top: calc(50% - 30px);
	width: 44px;
	height: 60px;
}

.baguetteBox-button {
	position: absolute;
	cursor: pointer;
	outline: none;
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 15%;
	color: #ddd;
	font: 1.6em sans-serif;
	transition: background-color .4s ease;
	background: transparent;
}

.baguetteBox-button:focus,
.baguetteBox-button:hover {
	background-color: rgba(50, 50, 50, 0.9);
}

.baguetteBox-button#next-button {
	right: 2%;
}

.baguetteBox-button#previous-button {
	left: 2%;
}

.baguetteBox-button#close-button {
	top: 20px;
	right: 2%;
	right: calc(2% + 6px);
	width: 30px;
	height: 30px;
}

.baguetteBox-button svg {
	position: absolute;
	left: 0;
	top: 0;
}


/*
	Preloader
	Borrowed from http://tobiasahlin.com/spinkit/
*/

.baguetteBox-spinner {
	width: 40px;
	height: 40px;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -20px;
	margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
	width: 100%;
	height: 100%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: #fff;
	opacity: .6;
	position: absolute;
	top: 0;
	left: 0;
	animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@keyframes bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
		-moz-transform: scale(0);
		transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		transform: scale(1);
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/


@include break(max, $screen-xs-max) {

}
@include break(min, $screen-xs-min, max, $screen-sm-max) {

}

/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
