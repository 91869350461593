/******************************************************************
███╗   ███╗██╗██╗  ██╗██╗███╗   ██╗███████╗
████╗ ████║██║╚██╗██╔╝██║████╗  ██║██╔════╝
██╔████╔██║██║ ╚███╔╝ ██║██╔██╗ ██║███████╗
██║╚██╔╝██║██║ ██╔██╗ ██║██║╚██╗██║╚════██║
██║ ╚═╝ ██║██║██╔╝ ██╗██║██║ ╚████║███████║
╚═╝     ╚═╝╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝╚══════╝
********************************************************************/

/*
██╗ ██████╗ ██████╗ ███╗   ██╗███████╗
██║██╔════╝██╔═══██╗████╗  ██║██╔════╝
██║██║     ██║   ██║██╔██╗ ██║███████╗
██║██║     ██║   ██║██║╚██╗██║╚════██║
██║╚██████╗╚██████╔╝██║ ╚████║███████║
╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@mixin icon($iconName) {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
	@if $iconName=="icon-calendar" {
		content: "\e900";
	}
	@if $iconName=="icon-cost-icon" {
		content: "\e901";
	}
	@if $iconName=="icon-email" {
		content: "\e902";
	}
	@if $iconName=="icon-facebook-icon" {
		content: "\e903";
	}
	@if $iconName=="icon-fax-icon" {
		content: "\e904";
	}
	@if $iconName=="icon-Instagram-icon" {
		content: "\e905";
	}
	@if $iconName=="icon-location-pin-icon" {
		content: "\e906";
	}
	@if $iconName=="icon-mobile-phone-icon" {
		content: "\e908";
	}
	@if $iconName=="icon-profile-icon" {
		content: "\e909";
	}
	@if $iconName=="icon-register-icon" {
		content: "\e90a";
	}
	@if $iconName=="icon-search-icon" {
		content: "\e90b";
	}
	@if $iconName=="icon-time" {
		content: "\e90c";
	}
	@if $iconName=="icon-twitter-icon" {
		content: "\e90d";
	}
	@if $iconName=="icon-youtube-icon" {
		content: "\e90e";
	}
	@if $iconName=="icon-minus-outline-icon" {
		content: "\e90f";
	}
	@if $iconName=="icon-page-top-arrow" {
		content: "\e910";
	}
	@if $iconName=="icon-plus-icon" {
		content: "\e911";
	}
	@if $iconName=="icon-quote-icon-1" {
		content: "\e913";
	}
	@if $iconName=="icon-quote-icon-2" {
		content: "\e914";
	}
	@if $iconName=="icon-video-player-icon" {
		content: "\e915";
	}
	@if $iconName==""{
		font-family: 'simple-line-icons';
    	
	}
	@if $iconName=="icon-arrow-down" {
		font-family: 'simple-line-icons';
        content: "\e604";
    }
    @if $iconName=="icon-arrow-left" {
    	font-family: 'simple-line-icons';
        content: "\e605";
    }
    @if $iconName=="icon-arrow-right" {
    	font-family: 'simple-line-icons';
        content: "\e606";
    }
    @if $iconName=="icon-arrow-up" {
    	font-family: 'simple-line-icons';
        content: "\e607";
    }
    @if $iconName=="icon-arrow-up-circle" {
    	font-family: 'simple-line-icons';
        content: "\e078";
    }
    @if $iconName=="icon-arrow-left-circle" {
    	font-family: 'simple-line-icons';
        content: "\e07a";
    }
    @if $iconName=="icon-arrow-right-circle" {
    	font-family: 'simple-line-icons';
        content: "\e079";
    }
    @if $iconName=="icon-arrow-down-circle" {
    	font-family: 'simple-line-icons';
        content: "\e07b";
    }
    @if $iconName=="icon-plus" {
    	font-family: 'simple-line-icons';
        content: "\e095";
    }
    @if $iconName=="icon-minus" {
    	font-family: 'simple-line-icons';
        content: "\e615";
    }
}


/*
 ██████╗██╗     ███████╗ █████╗ ██████╗ ███████╗██╗██╗  ██╗
██╔════╝██║     ██╔════╝██╔══██╗██╔══██╗██╔════╝██║╚██╗██╔╝
██║     ██║     █████╗  ███████║██████╔╝█████╗  ██║ ╚███╔╝ 
██║     ██║     ██╔══╝  ██╔══██║██╔══██╗██╔══╝  ██║ ██╔██╗ 
╚██████╗███████╗███████╗██║  ██║██║  ██║██║     ██║██╔╝ ██╗
 ╚═════╝╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝╚═╝  ╚═╝
*/

@mixin clearfix{
		&:before,
		&:after {
				content: " "; // 1
				display: table; // 2
		}
		&:after {
				clear: both;
		}
}


/*
 ██████╗ ██████╗  █████╗ ██████╗ ██╗███████╗███╗   ██╗████████╗███████╗
██╔════╝ ██╔══██╗██╔══██╗██╔══██╗██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝
██║  ███╗██████╔╝███████║██║  ██║██║█████╗  ██╔██╗ ██║   ██║   ███████╗
██║   ██║██╔══██╗██╔══██║██║  ██║██║██╔══╝  ██║╚██╗██║   ██║   ╚════██║
╚██████╔╝██║  ██║██║  ██║██████╔╝██║███████╗██║ ╚████║   ██║   ███████║
 ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝
*/


/* @include css-gradient(#dfdfdf,#f8f8f8); */

@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
		background-color: $to;
		background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
		background-image: -webkit-linear-gradient(top, $from, $to);
		background-image: -moz-linear-gradient(top, $from, $to);
		background-image: -o-linear-gradient(top, $from, $to);
		background-image: linear-gradient(to bottom, $from, $to);
}


/*
██████╗ ███████╗████████╗██╗███╗   ██╗ █████╗ 
██╔══██╗██╔════╝╚══██╔══╝██║████╗  ██║██╔══██╗
██████╔╝█████╗     ██║   ██║██╔██╗ ██║███████║
██╔══██╗██╔══╝     ██║   ██║██║╚██╗██║██╔══██║
██║  ██║███████╗   ██║   ██║██║ ╚████║██║  ██║
╚═╝  ╚═╝╚══════╝   ╚═╝   ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝
*/


/* @include background-image-retina(logo, png, 200px, 100px); */

@mixin background-image-retina($file, $type, $width, $height) {
		background-image: url($file + '.' + $type);
		@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
				& {
						background-image: url($file + '@2x.' + $type);
						-webkit-background-size: $width $height;
						-moz-background-size: $width $height;
						-o-background-size: $width $height;
						background-size: $width $height;
				}
		}
}


/*
 █████╗ ███╗   ██╗██╗███╗   ███╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
██╔══██╗████╗  ██║██║████╗ ████║██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
███████║██╔██╗ ██║██║██╔████╔██║███████║   ██║   ██║██║   ██║██╔██╗ ██║
██╔══██║██║╚██╗██║██║██║╚██╔╝██║██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
██║  ██║██║ ╚████║██║██║ ╚═╝ ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝     ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
*/

@mixin animation($animate...) {
		$max: length($animate);
		$animations: '';
		@for $i from 1 through $max {
				$animations: #{$animations + nth($animate, $i)};
				@if $i < $max {
						$animations: #{$animations + ", "};
				}
		}
		-webkit-animation: $animations;
		-moz-animation: $animations;
		-o-animation: $animations;
		animation: $animations;
}


/*
██╗  ██╗███████╗██╗   ██╗███████╗██████╗  █████╗ ███╗   ███╗███████╗
██║ ██╔╝██╔════╝╚██╗ ██╔╝██╔════╝██╔══██╗██╔══██╗████╗ ████║██╔════╝
█████╔╝ █████╗   ╚████╔╝ █████╗  ██████╔╝███████║██╔████╔██║█████╗  
██╔═██╗ ██╔══╝    ╚██╔╝  ██╔══╝  ██╔══██╗██╔══██║██║╚██╔╝██║██╔══╝  
██║  ██╗███████╗   ██║   ██║     ██║  ██║██║  ██║██║ ╚═╝ ██║███████╗
╚═╝  ╚═╝╚══════╝   ╚═╝   ╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝
*/


/*
@include keyframes(move-the-object) {
	0%   { left: 100px; }
	100% { left: 200px; }
}

.object-to-animate {
	@include animation('move-the-object .5s 1', 'move-the-object-again .5s 1 .5s');
} 
*/

@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} {
			@content;
	}
	@-moz-keyframes #{$animationName} {
			@content;
	}
	@-o-keyframes #{$animationName} {
			@content;
	}
	@keyframes #{$animationName} {
			@content;
	}
}

