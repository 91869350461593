@font-face {
	font-family: 'icomoon';
	src: url('fonts/icomoon.eot?evw4ts');
	src: url('fonts/icomoon.eot?evw4ts#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?evw4ts') format('truetype'), url('fonts/icomoon.woff?evw4ts') format('woff'), url('fonts/icomoon.svg?evw4ts#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
	content: "\e900";
}

.icon-cost-icon:before {
	content: "\e901";
}

.icon-email:before {
	content: "\e902";
}

.icon-facebook-icon:before {
	content: "\e903";
}

.icon-fax-icon:before {
	content: "\e904";
}

.icon-Instagram-icon:before {
	content: "\e905";
}

.icon-location-pin-icon:before {
	// Marker Point
	content: "\e907";
    position: absolute;
    color: white;
    z-index: 1;
}
.icon-location-pin-icon:after {
	// Marker Body
	content: "\e906";
	position: absolute;
}

.icon-mobile-phone-icon:before {
	content: "\e908";
}

.icon-profile-icon:before {
	content: "\e909";
}

.icon-register-icon:before {
	content: "\e90a";
}

.icon-search-icon:before {
	content: "\e90b";
}

.icon-time:before {
	content: "\e90c";
}

.icon-twitter-icon:before {
	content: "\e90d";
}

.icon-youtube-icon:before {
	content: "\e90e";
}

.icon-page-top-arrow:before {
	content: "\e910";
}

.icon-page-right-arrow:before {
	content: "\e910";
	transform: rotate(90deg);
}

.icon-quote-icon-1:before {
	content: "\e913";
}

.icon-quote-icon-2:before {
	content: "\e914";
}

.icon-video-player-icon:before {
	content: "\e915";
}