.share{
	height: 3rem;
}
.share__social{
	float: right;
}
.share__social-item{
	display: inline-block;
    margin: 0 0.4rem;
    img{
    	width: 2rem;
    	height: 2rem;
    }
    i{
    	font-size: 2rem;
    }
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
			color: $cda-blue--hover;
		}
	}
}
.content-block__section{
	.share{
		margin-top: -5.2rem;
	}
}
/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.content-block__section .share{
		margin-top: 0;
	}
	.share__social {
		float: none;
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {
	
}