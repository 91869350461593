.map-section {
	padding: 0;
	position: relative;
	.map {
		display: block;
		height: 50rem;
		width: 100%;
	}
	.map-overlay {
		padding: 0 2rem 2rem;
		position: absolute;
		top: 4rem;
		left: 20rem;
		background: $white;
	}
	.map-info {
		float: left;
		text-align: left;
		ul {
			margin-bottom: 4rem;
			list-style: none;
			a {
				display: block;
				margin-bottom: 2rem;
				padding: 0;
				padding-left: 4rem;
				position: relative;
				i{
					position: absolute;
					left: 2rem;
					font-size: 3rem;
					color: $cda-red;
					animation-name: none;
					left: 0;
				}
			}
		}
		
	}
	.marker {
		background: $cda-red;
		position: absolute;
		left: 50%;
		top: 50%;
		animation-name: bounce;
		animation-fill-mode: both;
		animation-duration: 1s;
		overflow: visible !important;
		&:after {
			content: url(../images/location-pin.svg);
			width: 3rem;
			height: 3rem;
			margin: 0;
			background: transparent;
			position: absolute;
		}
	}
	@keyframes bounce {
		0% {
			opacity: 0;
			filter: alpha(opacity=0);
			transform: translateY(-2000px);
		}
		60% {
			opacity: 1;
			filter: none;
			transform: translateY(30px);
		}
		80% {
			transform: translateY(-10px);
		}
		100% {
			transform: translateY(0);
		}
	}
}
/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/
@include break(max, $screen-sm-max) {
	.map-section {
		.section-title {
			display: none;
		}
		.map-overlay {
			margin-bottom: 2rem;
			position: static;
			padding: 1rem 2rem;
			width: 100%;
		}
		.map-info {
			float: none;
			ul {
				@include clearfix;
				a {
					float: left;
					width: 50%;
					padding: 0;
					padding-left: 4rem;
					position: relative;
					i{
						position: absolute;
						left: 0;
						font-size: 3rem;
						color: $cda-red;
						animation-name: none;
					}
				}
			}
		}
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/
@include break(min, $screen-md-min) {}
