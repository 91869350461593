.contact {
	.contact__header {
		text-align: center;
	}
	.social-box {
		border: 1px solid $white-translucent;
		max-width: 40rem;
		margin: 4rem auto;
		padding: 4rem 2rem;
		text-align: center;
		.social-box__header {
			@include heading('h3');
			display: block;
			padding: 0 1rem;
			background: $cda-red;
			width: 12rem;
			margin: auto;
			margin-top: -5.8rem;
			margin-bottom: 2rem;
		}
	}
	.social-box__social {
		font-size: 2rem;
		text-align: center;
		.social-box__social-item {
			float: none;
			display: inline-block;
			a {
				text-decoration: none;
				color: $white;
				transition: $transition--all;
				&:hover{
					text-decoration: none;
					color: $cda-grey;
					transition: $transition--all;
				}
				i {
					font-size: 3rem;
					padding: 0 1rem;
				}
				img{
					margin: 0 1rem;
					width: 3rem;
					height: 3rem;
				}
			}
		}
	}
	.contact__item {
		text-align: center;
		color: white;
		text-decoration: none;
		margin-left: auto;
		margin-right: auto;
		&:hover{
			color: white;
			text-decoration: none;
		}
		i {
			font-size: 6rem;
			display: block;
			padding: 2rem;
		}
		img{
			margin: 2rem;
			width: 6rem;
			height: 6rem;
		}
	}
	.contact__map {
		margin-left: auto;
		margin-right: auto;
		img {
			width: 100%;
			float: left;
    		max-width: 36rem;
    		margin-right: 2rem;
		}
	}
	.contact__row {
		margin-top: 1rem;
		margin-bottom: 4rem;
	}
	.contact__graphic {
		margin-left: auto;
		margin-right: auto;
		img {
			width: 100%;
			max-width: 36rem;
		}
	}
}

.contact-event {
	padding-top: 4rem;
    padding-bottom: 4rem;
	.contact-event__column {
		&:last-child {
			border-left: 1px solid $white-translucent;
		}
	}
	.contact-event__button {
		width: 30rem;
		text-transform: initial;
		margin: 2rem auto;
		display: block;
		i {
			float: left;
			font-size: 2rem;
			color: $cda-blue;
		}
		&:hover,&:focus{
			color:$cda-blue;
			text-decoration: none;
		}
	}
	.contact-event__info {
		display: block;
		margin-left: 2rem;
		margin-bottom: 1rem;
		position: relative;
		min-height: 6rem;
		a{
			color: $off-white;
			&:hover{
				color: $white;
			}
		}
		&:before,
		&:after {
			font-size: 2rem;
			position: absolute;
			opacity: 0.8;
			left: -6rem;
			top: 0.4rem;
			width: 4rem;
			height: 4rem;
			text-align: center;
		}
		&:after {
			font-size: 1.6rem;
			top: 2.8rem;
		}
		&.info--date {
			&:before {
				@include icon('icon-calendar');
			}
			&:after {
				content: "Date";
			}
		}
		&.info--time {
			&:before {
				@include icon('icon-time');
			}
			&:after {
				content: "Time";
			}
		}
		&.info--cost {
			&:before {
				@include icon('icon-cost-icon');
			}
			&:after {
				content: "Cost";
			}
		}
		&.info--name {
			&:before {
				@include icon('icon-profile-icon');
			}
			&:after {
				content: "Name";
			}
		}
		&.info--phone {
			&:before {
				@include icon('icon-mobile-phone-icon');
			}
			&:after {
				content: "Phone";
			}
		}
		&.info--email {
			&:before {
				@include icon('icon-email');
			}
			&:after {
				content: "Email";
			}
		}
		
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-md-max) {
	.contact-event .contact-event__column:last-child {
		border-left: none;
	}
	
	.contact{
		.contact__map img, .contact__graphic img {
			max-width: 28rem;
		}
	}
}

@include break(max, $screen-sm-max) {
	.contact {
		.article__header{
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
	.contact__graphic {
		margin-top: 2rem;
	}
	.contact-event {
		text-align: center;
		.contact-event__info{
			text-align: left;
		    margin-left: 6rem;
			border-bottom: 1px solid $white-translucent;
			&:after, &:before {
		    	left: -6rem;
			}
		}	
	}
}

@include break(max, $screen-sm-max) {
	.contact .social-box{
		border-left: none;
		border-right: none;
	}
}

/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝       
*/

@include break(min, $screen-md-min) {}
