.focus-testimonial {
	padding-top: 6rem;
	padding-bottom: 6rem;
	&.focus-testimonial--red {
		background-color: $cda-red;
		color: $white;
		.focus-testimonial__content:before,
		.focus-testimonial__content:after {
			color: $white;
			z-index: 0;
		}
	}
}

.focus-testimonial__image {
	img {
		width: 100%;
		border-radius: 50%;
		margin-top: 3rem;
		max-width: 32rem;
	}
}
.focus-testimonial__content {
	text-align: center;
	margin: 4rem auto;
	max-width: 60rem;
	p {
		font-size: 2rem;
		line-height: 1.6;
	}
}
.testimonial-block__quote,
.focus-testimonial__content {
	position: relative;
	&:before,
	&:after {
		position: absolute;
		color: $cda-red;
		font-size: 2.8rem;
	}
	
	&:before {
		@include icon('icon-quote-icon-1');
		left: -2rem;
		top: -2rem;
	}
	
	&:after {
		@include icon('icon-quote-icon-2');
		right: -2rem;
		bottom: 2rem;
	}
}
.testimonial-block__quote {
	padding: 0 3rem;
	margin-bottom: 1.2rem;
}
.testimonial-block__footer {
	padding-left: 2.2rem;
	img {
		border-radius: 50%;
		float: left;
		height: 8rem;
		width: 8rem;
		margin-right: 1rem;
		margin-top: -1rem;
	}
}
.testimonial-block__name {
	// color: $nacac_purple;
	font-size: 2rem;
	margin-top: 0.4rem;
	margin-bottom: 0.8rem;
}
.testimonial-block__title {
	margin-top: 0.8rem;
}

/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/
@include break(max, $screen-sm-max) {
	.focus-testimonial__image {
		margin: auto;
		width: 28rem;
		text-align: center;
	}
	
	.testimonial-block {
		padding-bottom: 2rem;
		padding-top: 3.4rem;
		p.testimonial-block__name {
			font-size: 1.6rem;
			font-weight: bold;
		}
	}
	
	.focus-testimonial__content .article__header {
		text-align: center;
	}
	.focus-testimonial--red .focus-testimonial__content:before{
		color: red;
	}
	.focus-testimonial__content:before {
		left: 0;
		top: -3rem;
		font-size: 8rem;
		opacity: 0.2;
		z-index: -1;
	}
	
	.focus-testimonial__content:after {
		content: "";
	}
}

/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/
@include break(min, $screen-md-min) {
	.focus-testimonials {
		background-color: transparent;
	}
}
