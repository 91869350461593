$big-border-radius:12rem;
.events-list {
	border: 1px solid $cda-grey;
	border-top: 3px solid $cda-red;
	border-bottom-right-radius: $big-border-radius;
	background-color: $off-white;
	padding-bottom: 4rem;
	margin-top: 3rem;
	&.events-list--full-width{
		width: 100%;
		background-color: transparent;
		border: none;
		.pagination__nav{
			text-align: center;
		}

	}
	h2 {
		text-align: center;
	}
	.events-list__set {
		list-style: none;
		padding: 0;
	}
	.events-list__items {
		display: block;
		min-height: 12rem;
		margin: 1rem;
		&.events-list--thumbnails{
			.events-list__content{
				width: calc(100% - 19rem);
			}
		}
		.events-list__link {
			padding: 1rem;
			display: block;
			border: 1px solid transparent;
			@include clearfix;
			transition: $transition--all;
			h3{
				position: relative;
				display: inline;
				color: $text-slate;
				transition: all .2s ease-in-out;
				box-shadow: inset 0px -0.1em 0px 0px transparent;
				text-decoration: none;
			}
			&:hover, &:active {
				text-decoration: none;
				transition: $transition--all;
				background-color: $white;
				border-color: $cda-grey;
				h3 {
					color: $cda-blue--hover;
					transition: $transition--all;
					box-shadow: inset 0px -0.1em 0px 0px $cda-blue--hover;
					text-decoration: none;
				}
			}
		}
	}
	.events-list__content {
		display: block;
		padding-left: 1rem;
		padding-right: 1rem;
		color: $text-slate;
		@include break(min, $screen-md-min) {
			float: left;
			width: calc(100% - 10rem);
		}
		h3,
		p {
			margin: 0;
			padding: 0;

		}
		h3 {
			color: $text-slate;
			transition: $transition--all;
			line-height: 1;
			margin-bottom: 0.4rem;
		}
	}
	.events-list__date {
		margin-bottom: 0;
		color: $cda-red;
	}
	span {
		display: block;
	}
	.events-list__img {
		float: left;
		width: 9rem;
		height: 9rem;
	}
	// .events-list__location, .events-list__description{
	// 	color: $text-slate;
	// }
	.calendar-icon {
		margin: 0 2rem 0 0;
		float: left;
		height: 9rem;
		width: 8rem;
		position: relative;
		text-align: center;
		background-color: $white;
		border: 1px solid $cda-grey;
	}
	.calendar-icon__month {
		position: absolute;
		top: 0;
		height: 2.8rem;
		font-size: 1.2rem;
		padding-top: 0.4rem;
		color: $white;
		background-color: $cda-red;
		width: 100%;
	}
	.calendar-icon__day {
		font-size: 4rem;
		margin-top: 3rem;
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.events-list {
		//border: none;
		//background-color: transparent;
		//border-bottom-right-radius: 0;
		.calendar-icon__month {
			position: relative;
			top: 0;
			height: 2rem;
			font-size: 1.2rem;
			padding-top: 0.4rem;
			color: $white;
			background-color: $cda-red;
			width: 100%;
			color: transparent;
			&:after {
				content: attr(data-month);
				position: absolute;
				top: 0;
				left: 0;
				text-transform: uppercase;
				text-align: center;
				width: 4rem;
				color: $white;
			}
		}
		.calendar-icon {
			margin: 0 0.8rem 0 -0.2rem;
			width: 4rem;
			height: 5.6rem;
			.calendar-icon__day {
				font-size: 2rem;
				margin-top: 0rem;
			}
		}
		.events-list__items.events-list--thumbnails .events-list__content {
			width: calc(100% - 4.6rem);
		}
		.events-list__img {
			display: none;
		}
		&.events-list--full-width {
			.events-list__items .events-list__content{
				width: 100%;
			}
			.calendar-icon{
				display: none;
			}
		}
	}
}

//TABLET

@include break(max, $screen-md-max){
	.events-list {
		.button {
			max-width: 14rem;
			padding-right:1rem;
			padding-left: 1rem;
		}
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
