$utility-height: 5.2rem;
.utility {
  color: $white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: $transition--all;
  background-color: $cda_blue;
  height: $utility-height;
  a {
    color: $white;
    vertical-align: middle;
  }
  a,
  a:hover {
    text-decoration: none;
  }
  ul {
    list-style: none;
    float: left;
    margin-bottom: 0;
    padding: 0;
    li {
      float: left;
      padding: 0 1.6rem;
      height: 5.2rem;
    }
  }
  // Left Most
  .utility__list {
    width: 50%;
    padding: 0;
    list-style-type: none;
    .utility__item {
      display: inline-block;
      padding: 1.6rem 2rem 0 0;
      a {
        font-size: 1.2rem;
        color: $white;
        cursor: pointer;
      }
    }
  }
  // Middle
  .utility__social {
    width: 30%;
    font-size: 2rem;
    line-height: 4.6rem;
    .utility__social-item {
      img {
        width: 2rem;
        height: 2rem;
      }
    }
    .button {
      margin-top: 0;
      line-height: 1.5;
      height: 3.6rem;
    }
  }
  // Right
  .utility-search {
    float: left;
    width: 20%;
    text-align: right;
    padding: 0 1rem;
    position: relative;
    display: block;
    height: $utility-height;
    .utility-search__input {
      padding: 0.6rem;
      float: left;
      margin: 1rem 0;
      width: calc(100% - 3rem);
      background-color: transparent;
      border: 1px solid $white;
      border-right: none;
      color: $white;
      font-size: 1.4rem;
      outline: none;
      height: 33px;
      &::-webkit-input-placeholder {
        color: #ededed;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #ededed;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #ededed;
      }
      &:-ms-input-placeholder {
        color: #ededed;
      }
    }
    .utility-search__trigger {
      width: 33px;
      height: 33px;
      display: block;
      position: absolute;
      background: white;
      color: $text_slate;
      border: 1px solid #FFF;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      padding: 8px;
      opacity: 1;
      transition: $transition--all;
      font-weight: bold;
      input {
        position: absolute;
        background: transparent;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
    }
    .utility-search__close {
      opacity: 0;
      visibility: hidden;
    }
  }
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/


@include break(max, $screen-sm-max) {
  .utility {
    position: fixed;
    width: 100%;
    height: 14rem;
    font-size: 1.4rem;
    z-index: 3;
    border-bottom: 7rem solid white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
    .utility__logo--mobile {
      width: 20rem;
      position: absolute;
      top: 6rem;
      img {
        width: 100%;
      }
    }
    &.utility--scrolled {
      height: 8rem;
      .mobile-nav__logo img {
        width: 13rem;
        transition: $transition--all;
      }
      .utility__list {
        margin: 1rem 0;
        transition: $transition--all;
      }
    }
    ul {
      position: absolute;
      list-style: none;
      bottom: 0;
      li {
        padding: 0 0.4rem;
        height: auto;
      }
    }
    .utility__list {
      display: none;
    }
    .utility__social {
      display: none;
    }
    .utility-search {
      float: left;
      text-align: left;
      padding: 1rem 0;
      height: 5rem;
      top: 0;
      height: 6rem;
      width: calc(100% - 10rem);
      &:before {
        display: none;
      }
      .utility-search__input {
        padding: .6rem;
        margin-top: 0.6rem;
        width: 30rem;
      }
      .utility-search__trigger {
        font-size: 2rem;
        padding: 5px;
        margin: 0;
        height: 3.3rem;
        top: 1.6rem;
        right: 30rem;
        text-align: center;
        &:after {
          top: 1rem;
        }
      }
      .utility-search__close {
        margin-top: 5px;
        margin-right: -5px;
      }
    }
  }
}

@include break(max, $screen-xs-max) {
  .utility .utility-search{
    .utility-search__input {
      width: 100%;
    }
    .utility-search__trigger {
      right: 0;
    }
  }
}
/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝      
*/

@include break(min, $screen-sm-min, max, $screen-md-max) {
  .utility ul li {
    padding: 0 1rem;
    font-size: 1.6rem;
  }
}

@include break(min, $screen-md-min) {
  .utility {
    .utility__logo--mobile {
      display: none;
    }
    .utility-search__wrapper input.utility-search__input {
      border-bottom: 1px solid;
      padding-bottom: 2rem;
      opacity: 0;
      transition: $transition--all;
    }
    .utility-search.utility-search--open input.utility-search__input {
      opacity: 1;
      transition: $transition--all;
      transition-delay: 400ms;
    }
  }
}
