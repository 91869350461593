.slider {
	position: relative;
	background: $cda-grey;
}

.frame {
	width: 100%;
	position: relative;
	font-size: 0;
	overflow: hidden;
	white-space: nowrap;
	height: 50rem;
}

.slides {
	display: inline-block;
	width: 100%;
	.slide__item {
		position: relative;
		display: inline-block;
		overflow: hidden;
		width: 100%;
	}
	.slide__img {
		width: 100%;
		background-size: cover;
		height: 50rem;
		background-position: 50%;
	}
	.slide__content {
		position: absolute;
		z-index: 1;
		top: 6rem;
		text-align: center;
		width: 100%;
		@include absolute--center;
		display: block;
		margin-top: auto;
		margin-bottom: auto;
		padding: 0 2rem;
		max-width: 99rem;
		white-space: normal;
		color: $white;
		text-shadow: 0 0 1.6rem black;
		background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 10%,rgba(0,0,0,0) 60%);
		span{
			margin-top: 4rem;
			display: inline-block;
			text-transform: uppercase;
		}
		h2 {
			font-size: 4.8rem;
			margin: 4rem auto;
		}
		p {
			font-size: 2rem;
			line-height: 1.4;
			margin: 4rem auto;
			padding: 0 6em;
		}
		.button{
			margin-bottom: 4em;
			&:hover {
				text-shadow: none;
			}
		}
	}
}

.slider-nav {
	width: 100%;
	bottom: 9px;
	height: 11px;
	display: block;
	position: absolute;
	text-align: center;
	.slider-nav__dot {
		top: -5px;
		width: 11px;
		height: 11px;
		margin: 0 4px;
		position: relative;
		border-radius: 100%;
		display: inline-block;
		background-color: $off-white;
		transition: $transition--all;
		box-shadow: 0 0 8px black;
		&:hover,
		&.slider-nav__dot--active {
			transition: $transition--all;
			cursor: pointer;
			background-color: $cda-orange;
		}
	}
}

.slider__aside {
	position: absolute;
	top: 6rem;
	background-color: $cda-blue;
	width: 32rem;
	border-bottom-right-radius: 12rem;
	color: white;
	padding: 4rem;
	padding-top: 0;
	padding-bottom: 8rem;
	transition: $transition--all;
	&.slider--no-control {
    padding-bottom: 1rem;
	}
	.slider__control {
		position: relative;
		width: 30rem;
		cursor: pointer;
		user-select: none;
		.slider__timer {
			position: absolute;
			right: -1px;
			top: 6px;
		}
		.slider__arrow {
			font-size: 46px;
			position: absolute;
			z-index: 0;
			left: 0;
			&.slider__arrow--left {}
			&.slider__arrow--right {
				left: 8rem;
			}
		}
	}
	span{
		margin-top: 4rem;
		display: inline-block;
		text-transform: uppercase;
	}
	h2 {
		margin-top: 1rem;
		line-height: 1.2;
	}
}

.slide__aside-content {
	display: none;
	margin-bottom: 4rem;
	&.aside-content--active {
		display: block;
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.slides {
		.slide__img {
			width: auto;
			min-height: 500px;
		}
		.slide__content {
			left: 0;
			max-width: 90vw;
			h2 {
				font-size: 4rem;
				margin: 0;
			}
			p {
				font-size: 1.6rem;
				line-height: 1.4;
				padding: 1rem;
				margin: 2rem auto;
			}
		}
	}
	.slider__aside {
		width: 100%;
		border-radius: 0;
		top: 0;
		background: transparent;
		background: linear-gradient(to bottom, $black 0%, rgba(37, 141, 200, 0) 100%);
		.slider__control {
			width: 0;
		}
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {
	
}
@include break(min, $screen-lg-min) {
	.slider__aside{
		width: 37rem;
		margin-left: auto;
		margin-right: auto;
		left: -81.4rem;
		right: 0;
		top: 0;
		padding-top: 4rem;
		transition: $transition--all;
	}
}
