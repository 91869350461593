// .Safari{
// 	.col-sm{
// 		flex: 1 calc(25% - 2rem);
// 	}
// }

/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.Safari-9, .Safari-10{
		.footer{
			.col-sm,{
				flex: 0 0 auto !important;
			}
		}
	}
	.Safari-8{
		.primary-nav{
			-webkit-transform: translateZ(0) translateX(100%);
		}
		.primary-nav[data-open=true] {
			-webkit-transform: translateZ(0) translateX(0);
		}

	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {
	.Safari-9, .Safari-10{
		.card.col-sm-3{
			flex-grow: 1 !important;
			flex-shrink: 1 !important;
			flex-basis: calc(25% - 3rem) !important;
		}
		.footer{
			.col-md-4{
				flex: 1 calc(33.33% - 2rem) !important;
			}
		}
		.gallery__row{
			.col-sm-4{
				flex: 1 calc(33.33% - 2rem) !important;
			}
		}
		.primary-nav__item{
			.col-md-3{
				flex: 1 calc(25% - 2rem) !important;
			}
		}
	}
	.IE-11, .Edge-14{
		.primary-nav {
			.primary-nav__interior {
				padding: 0;
			}
			.primary-nav__interior > li {
				visibility: hidden;
				display: none;
			}
			[data-open=true] > .primary-nav__interior > li{
				cursor: default;
				visibility: visible;
				display: table-cell;
				padding-bottom: 2rem;
			}
			.primary-nav__item{
				cursor: pointer;
			}
		}
	}
}