$transition--slow: all 400ms;
.accordion__toggle {
    text-align: right;
    cursor: pointer;
    color: $cda-blue;
    i {
        margin-top: 2rem;
        margin-right: 3rem;
        margin-left: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 2.6rem;
        z-index: 0;
        text-align: center;
    }
}

.accordion {
    list-style: none;
    margin: 2rem 0;
    .accordion__jump{
        margin-top: -30rem;
        // padding-bottom: 30rem;
        display: block;
        z-index: -1;
        pointer-events: none;
        cursor: default;
    }
    .accordion__tab {
        text-decoration: none;
        color: $cda-blue;
        font-family: $serif;
        @include heading(h3);
        margin: 0;
        position: relative;
        display: block;
        padding: 2rem 0;
        padding-right: 4rem;
        cursor: pointer;
        &:after {
            @include icon('icon-plus');
            color: $cda-blue;
            position: absolute;
            top: 2rem;
            right: 1rem;
            width: 2.5rem;
            height: 2.5rem;
            font-size: 2.5rem;
            z-index: 0;
            text-align: center;
            cursor: pointer;
            transform: rotate(0);
            transition: $transition--all;
        }
    }
    .accordion__panel {
        padding-right: 4rem;
        max-height: 0;
        opacity: 0;
        transition: $transition--slow;
        padding-bottom: 2rem;
    }
    .accordion__item {
        padding: 0 2rem;
        min-height: 10rem;
        border-bottom: 1px solid $border-grey;
        // max-height: 12rem;
        height: auto;
        transition: $transition--all;
        overflow: hidden;
        &:first-child {
            border-top: 1px solid $border-grey;
        }
        &.accordion__item--selected {
            color: $text-slate;
            // max-height: 36rem;
            // background-color: $cda-blue;
            
            .accordion__panel {
                color: $text-slate;
                max-height: 42rem;
                opacity: 1;
                transition: $transition--slow;
                // padding-top: 30rem;
            }
            .accordion__tab:after {
                @include icon('icon-minus');
                // color: #FFF;
                transform: rotate(-180deg);
                transition: $transition--all;
            }
            a {
                text-decoration: none;
                // color: $white;
            }
        }
    }
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-xs-max) {
    
    .accordion .accordion__item.accordion__item--selected {
        .accordion__panel {
            max-height: 100rem;
            transition: none;
        }
    }
}

@include break(max, $screen-sm-max) {
    .accordion .accordion__item{
        transition: none;
    }
    .accordion .accordion__item.accordion__item--selected {
        .accordion__panel {
            max-height: 140rem;
            transition: none;
        }
    }
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
