.footer{	
	font-size: 1.4rem;
	padding-top: 4rem;
	.footer__row{
		min-height: 50rem;
		position: relative;
		.footer__logo{
			position: absolute;
			top:8rem;
			left:0;
			height: 40rem;
		}
	}
	ul{
		list-style-type: none;
		padding: 0;
		margin: 0;
		li{
			margin-bottom: 0.4rem;
		}
		a{
			
			color: inherit;
			transition: $transition--all;
			&:hover, &:active{
				text-decoration: none;
				color: $cda-red--hover;
				transition: $transition--all;
			}
		}
		h3{
			line-height: 1.2;
		}
	}
	.list--by-2{
		width: 50%;
		float: left;
		display: block;
		padding-right: 1rem;
	}
	.list--by-3{
		width: 33.333%;
		float: left;
		display: block;
		padding-right: 1rem;
	}
	.footer__social-item{
		display: inline-block;
		margin-right: 2rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		i{
			font-size: 2.6rem;
			color: $cda-blue;
		}
		img{
			width: 2.6rem;
			height: 2.6rem;
		}
	}
	.footer__byline{
		margin-top: 4rem;
		padding: 1rem 0;
		font-size: 1.6rem;
		background-color: $cda-dark-grey;
		color: $white;
		& a{
			color: $white;
		}

	}
	.footer__translate{
		float: right;
	}
}

/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.footer {
		h3{
			margin-top: 0;
		}
		.footer__row {
			.row {
				display: block;
			}
			line-height: 1;
			ul,li{
				margin: 0;
			}
			.footer__logo {
				position: absolute;
				top: 8rem;
				left: 0;
				height: 40rem;
				width: 100%;
				opacity: .6;
				img {
					margin: auto;
					max-width: 32rem;
					display: block;
				}
			}
			.footer__row--hidden{
				display: none;
			}
		}
	}
}



/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {
	.footer{	
		.footer__row{
			li h3 a{
				transition: all .3s ease-in-out;
				&:hover{
					box-shadow: inset 0 -.1em 0 0 $cda-red--hover;
					color: $cda-red--hover;
					trasition: $transition--all;
				}
			}
			.footer__contact {
				padding-left: 3.4rem;
				padding-top: 2rem;
				border-left: 1px solid $border-grey;
			}
			.footer__logo {
				position: absolute;
				top: 8rem;
				left: 0;
				height: 40rem;
				width: 100%;
				opacity: 1;
				img {
					// margin: auto;
					max-height: 40rem;
					display: block;
				}
			}
		}
	}
}
@include break(min, $screen-md-min, max, $screen-md-max) {
	.footer{	
		.footer__row{
			h3{
				font-size: 1.8rem;
				line-height: 1;
				margin-top: 0;
			}
			ul{
				padding-top: 2rem;
			}
		}
	}
}