// Accessiblity Skip Nav Link
.skip-nav {
	display: block;
	z-index: 1;
	position:absolute;
	height: 1px;
	width: 1px;
	overflow: hidden;
	left: -1000px;
	top: -1000px;
	white-space: nowrap;
	padding: 10px;
	background: #fff;
}

.skip-nav:focus,
.skip-nav:active,
.skip-nav:hover {
	position: absolute;
	left: 0;
	top: 0;
	height: auto;
	width: auto;
	overflow: hidden;
	z-index: 999;
}

// Primary Nav
.primary-nav__trigger {
	display: none;
	cursor: pointer;
	position: fixed;
	z-index: 3;
	top: 1rem;
	right: 1rem;
	padding: 0.8rem;
	transition: $transition--all;
	color: $white;
	&:hover,
	&:active,
	&:focus {
		color: $white;
		text-decoration: none;
	}
	@media(max-width: $screen-sm-max) {
		display: inline-block;
	}
	span.menu-text {
		line-height: 1.6;
		padding-right: 1rem;
	}
	.trigger__icon {
		width: 16px;
		height: 16px;
		margin-top: 4px;
		position: relative;
		float: right;
		transform: rotate(0deg);
		transition: .5s ease-in-out;
		span {
			display: block;
			position: absolute;
			height: 1px;
			border-radius: 1px;
			width: 100%;
			background: $white;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
		}
		span:nth-child(1) {
			top: 0;
		}
		span:nth-child(2),
		span:nth-child(3) {
			top: 6px;
		}
		span:nth-child(4) {
			top: 12px;
		}
	}
	.trigger--x {
		transform: rotate(180deg);
	}
	.trigger__icon.trigger--x span:nth-child(1) {
		top: 12px;
		width: 0%;
		left: 50%;
	}
	.trigger__icon.trigger--x span:nth-child(2) {
		transform: rotate(45deg);
		transform-origin: 6px 2px;
		height: 1px;
	}
	.trigger__icon.trigger--x span:nth-child(3) {
		transform: rotate(-45deg);
		transform-origin: 10px 1px;
		height: 1px;
	}
	.trigger__icon.trigger--x span:nth-child(4) {
		top: 1px;
		width: 0%;
		left: 50%;
	}
}

.primary-nav {
	.navigation__translation {
		position: absolute;
		right: 1rem;
		margin: 0;
		top: -1.2rem;
		font-size: 1.2rem;
		// font-weight: bold;
	}
	.primary-nav__list {
		list-style: none;
		display: table;
		width: 100%;
		position: relative;
		background-color: $white;
		box-shadow: 780px 0 0 0px $white, -780px 0 0 0px $white;
		.primary-nav__interior {
			h3 {
				// @include sans-uppercase-normal;
			}
		}
	}
	.primary-nav__item {
		background-color: $white;
		line-height: 1;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
		height: 7rem;
		cursor: pointer;
		font-size: 1.4rem;
		> a {
			color: $text-slate;
			font-family: $sans;
			display: block;
			position: relative;
			pointer-events: none;
			transition: $transition--all;
		}
		&:hover,
		&:active,
		&:focus > a {
			color: $cda-red;
			transition: $transition--all;
		}
	}
	.primary-nav__logo {
		background-color: $white;
		line-height: 1;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
		height: 7rem;
		cursor: pointer;
		font-size: 1.4rem;
	}
	.interior__inception {
		color: $cda-medium-blue;
		> ul {
			margin-top: 1rem;
			padding-left: 1.6rem;
		}
	}
	.primary-nav__utility-list {
		display: none;
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.primary-nav__trigger {
		display: inline-block;
	}
	.primary-nav {
		height: auto;
		transform: translateZ(0) translateX(100%);
		transition: $transition--all;
		z-index: 2;
		position: fixed;
		top: 14rem;
		right: 0;
		margin: 0;
		&[data-open=true] {
			transform: translateZ(0) translateX(0);
		}
		.container {
			padding-left: 0;
			padding-right: 0;
			width: 100%;
		}
		ul {
			list-style: none;
		}
		.primary-nav__list {
			box-shadow: none;
			height: calc(100vh - 14rem);
			width: 100vw;
			overflow: auto;
			overflow-x: hidden;
			padding: 0;
			display: block;
			>li,
			a {
				width: 100%;
				display: block;
				text-align: left;
				font-size: 1.6rem;
			}
			.navigation__translation{
				background-color: $off-white;
				border-bottom: 1px solid $cda-grey;
				position: relative;
				display: inline-block;
				padding: 2rem;
				// text-align: center;
				z-index: 4;
				top: 0;
				right: 0;
				a{
					display: inline;
				}
			}
			.primary-nav__logo {
				display: none;
			}
			.primary-nav__item {
				position: relative;
				height: auto;
				border-bottom: 1px solid $cda-grey;
				overflow: hidden;
				font-size: 1.6rem;
				.primary-nav--children i {
					display: none;
				}
				> a {
					padding: 2rem;
					font-size: 1.6rem;
					&.primary-nav--children:after {
						position: absolute;
						right: 1rem;
						top: 2rem;
						height: 2rem;
						// color: $nacac_blue;
						width: 2rem;
						@include icon('icon-plus');
						transition: $transition--all;
						font-size: 2rem;
						text-align: center;
					}
				}
				.primary-nav__interior {
					max-height: 0;
					padding: 0;
					transition: max-height 600ms ease-in-out;
					// WHY DID I HAVE THIS!?
					// overflow: hidden;
				}
				&[data-open=true] {
					.primary-nav--children:after {
						@include icon('icon-minus');
						transform: rotate(180deg);
						transition: $transition--all;
					}
					.primary-nav__interior {
						max-height: 126rem;
						transition: max-height 600ms ease-in-out;
						padding-bottom: 2rem;
					}
				}
			}
		}
		.primary-nav__interior {
			ul {
				padding: 0 1rem;
			}
			> li {
				padding: 0 2rem;
				padding-left: 3.6rem;
				margin-bottom: 1rem;
				ul.interior__links {
					line-height: 1.4;
				}
				h4,
				h4 > a {
					font-family: $sans;
					color: $cda-red;
					margin: 0 0 1rem 0;
					font-size: 1.6rem;
				}
			}
		}
		.nav__feature {
			display: none;
		}
		.primary-nav__utility-list {
			display: block;
			padding: 2rem;
			li {
				padding-bottom: 1rem;
				font-size: 1.4rem;
			}
		}
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     													  
*/

@include break(min, $screen-md-min) {
	.nav-header-buttons {
		display: none;
	}
	.primary-nav {
		position: fixed;
		padding: 0;
		width: 100%;
		overflow: visible;
		z-index: 3;
		top: 5.2rem;
		height: 9.6rem;
		border-top: 2.4rem solid $white;
		border-bottom: 2px solid $border-grey;
		background-color: $white;
		.primary-nav__interior {
			top: 7rem;
			bottom: inherit;
			transform-origin: top;
			visibility: hidden;
			// display: none;
		}
		&:after {
			clear: both;
			content: "";
			display: table;
		}
		ul {
			position: static;
			margin: 0;
			padding: 0;
			height: auto;
			width: auto;
			background: transparent;
			overflow: visible;
			list-style: none;
		}
		>li {
			margin: 0;
			color: $white;
			padding: 0 .5em;
			text-align: center;
			display: inline-block;
		}
		.primary-nav__item {
			padding: 0.8rem;
			> a {
				font-size: 1.6rem;
				// Red Under Line when Open
				&:before {
					clear: both;
					content: "";
					display: table;
					position: absolute;
					background: $cda_red;
					height: 4px;
					width: 100%;
					top: 3.9rem;
					left: 0;
					opacity: 0;
					transition: opacity 500ms ease-in-out;
				}
			}
			// Right Border Separator
			&.primary-nav__item--border {
				padding-right: 1.2rem;
			}
			&.primary-nav__item--border > a:after {
				content: "";
				display: block;
				position: absolute;
				right: -1rem;
				width: 0;
				top: 50%;
				margin-top: -1rem;
				height: 2rem;
				margin-left: 5rem;
				border-left: 1px solid $border-grey;
			}
			i.icons {
				font-size: 1rem;
				padding-left: 2px;
			}
			// Dropdown Open Link
			&[data-open=true] {
				> a {
					color: $cda_red;
					position: relative;
					&:before {
						transition: opacity 400ms;
						transition-delay: 200ms;
						opacity: 1;
					}
				}
				
			}

		}
		.primary-nav__logo {
			padding: 0.8rem;
			margin: 0;
			margin-top: -4.4rem;
			display: block;
			background-color: transparent;
			width: 26rem;
			margin-left: -8rem;
			padding: 0;
			img {
				width: 100%;
			}
		}
		// Dropdown Open!
		[data-open=true] > .primary-nav__interior {
			visibility: visible;
			cursor: default;
			transform: translateX(0);
			opacity: 1;
			pointer-events: all;
			z-index: 2;
		}
		// Dropdown Closed!
		.primary-nav__interior {
			visibility: hidden;
			box-shadow: 780px 0 0 0px $off-white, -780px 0 0 0px $off-white;
			position: absolute;
			width: 100%;
			left: 0;
			top: 7.2rem;
			background: $off-white;
			padding: 2em 0;
			text-align: left;
			color: #333;
			// overflow: hidden;
			cursor: auto;
			pointer-events: none;
			// Some Classy slide in Animation
			transform: translateX(-6rem);
			opacity: 0;
			transition: $transition--all;
			&:before {
				clear: both;
				content: "";
				display: block;
				position: absolute;
				height: 2px;
				width: 200vw;
				left: -50vw;
				bottom: 0;
				background: $cda_red;
			}
			.interior__links {
				li {
					margin-bottom: 1rem;
				}
			}
			> li {
				float: left;
				width: 25%;
				margin: 0;
				padding-right: 4rem;
				min-height: 4rem;
				h4 {
					font-size: 1.8rem;
					margin-top: 0.6rem;
					margin-bottom: 1rem;
					> a {
						color: $cda_red;
						transition: $transition--all;
						box-shadow: none;
						&:after {
							display: none;
						}
						&:hover,
						&:active,
						&:focus {
							box-shadow: inset 0 -.1em 0 0 $cda-red--hover;
							color: $cda-red--hover;
						}
					}
				}
				a {
					color: $text_slate;
					position: relative;
					line-height: 1.4;
					box-shadow: inset 0px -0.1em 0px 0px transparent;
					text-decoration: none;
					transition: $transition--all;
					&:hover,
					&:focus {
						color: $cda-blue--hover;
						box-shadow: inset 0px -0.1em 0px 0px $cda-blue--hover;
						text-decoration: none;
						transition: $transition--all;
					}
				}
				img {
					width: 100%;
				}
				p {
					margin: 1rem 0;
					line-height: 2rem;
				}
				&.nav-article {
					float: right;
					width: 33%;
					.content-block-image-wrapper {
						margin-bottom: 1em;
					}
					p {
						margin-bottom: 0;
					}
				}
				ul.primary-nav__interior {
					position: relative;
					top: 0;
					max-width: 20rem;
					margin: 0;
					padding: 0;
					list-style: none;
					li {
						float: none;
						width: 100%;
						margin-top: 0;
					}
				}
			}
		}
	}
}

// // In between
@include break(min, $screen-md-min, max, $screen-lg) {
	.primary-nav .primary-nav__item {
		font-size: 1.36rem;
		&[data-open=true]:after {
			border-left-width: 50px;
			border-right-width: 50px;
		}
	}
	.primary-nav__logo {
		width: 20rem;
		margin-left: -4rem;	
	}
}

// Odd size for Logo and Icons
@include break(max, 1333px) {
	.primary-nav .primary-nav__logo {
		width: 22rem;
		margin-left: -2rem;
		margin-top: -3.3rem;
	}
	.primary-nav .primary-nav__item>a {
		font-size: 1.4rem;
	}
	.primary-nav .primary-nav__item i.icons {
		font-size: 0.7rem;
		position: absolute;
		top: 0.4rem;
		right: -0.8rem;
	}
	.primary-nav .primary-nav__item.primary-nav__item--border>a:after {
		right: -1.5rem;
	}
}
