.feature {
	&.feature--aside {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
	.feature__background-image {
		max-height: 50rem;
		overflow: hidden;
		img {
			width: 100%;
		}
	}
	.feature__aside-image {
		img {
			width: 100%;
		}
	}
	.feature__content {
		h2 {
			margin-top: 0;
			margin-bottom: 1rem;
			line-height: 1;
		}
		.feature__byline {
			color: $text-slate;
		}
		padding: 0 4rem;
		.button {
			margin-top: 2rem;
		}
	}
	&:nth-child(odd){
		background-color: $off-white;
		.feature__aside-image {
			float: left;
			width: 40%;
		}
		.feature__content {
			float: left;
			width: 60%;

		}
	}
	&:nth-child(even){
		.feature__aside-image {
			float: right;
			width: 40%;
		}
		.feature__content {
			float: right;
			width: 60%;
		}
	}
}


/*
██████╗ ██╗  ██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██║  ██║██╔═══██╗████╗  ██║██╔════╝
██████╔╝███████║██║   ██║██╔██╗ ██║█████╗  
██╔═══╝ ██╔══██║██║   ██║██║╚██╗██║██╔══╝  
██║     ██║  ██║╚██████╔╝██║ ╚████║███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

@include break(max, $screen-sm-max) {
	.feature.feature--aside:nth-child(odd),.feature.feature--aside:nth-child(even) {
		.feature__aside-image {
			float: none;
			width: 100%;
			padding: 4rem;
			padding-bottom: 2rem;
		}
		.feature__content {
			float: none;
			width: 100%;
			.button {
				float: none;
				margin-top: 1rem;
				margin-left: 0;
			}
		}
	}
}


/*
██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
*/

@include break(min, $screen-md-min) {}
