
html {
	font-size: 10px;
	-webkit-font-smoothing: antialiased;
}

body {
	font-family: $sans;
	color: $text_slate;
	font-size: 1.6rem;
}

p {
	line-height: 2.3rem;
	margin-top: 0;
	margin-bottom: 1.6rem;
}


/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $serif;
	font-weight: 400;
	margin-top: 1.5rem;
	margin-bottom: 0.8rem;
	line-height: 1.5rem;
}

h1 {
	font-size: 4.242rem;
	line-height: 7rem;
	margin-top: 3rem;
}

h2 {
	font-size: 3.2rem;
	line-height: 5rem;
	margin-top: 3rem;
}

h3 {
	font-size: 2.121rem;
	line-height: 3rem;
	margin-top: 3rem;
	color: $cda-red;
}

h4 {
	font-size: 1.686rem;
	line-height: 2.4rem;
}

h5 {
	font-size: 0.707rem;
	font-family: $sans;
}

h6 {
	font-size: 0.4713333333333333rem;
	font-family: $sans;
	font-weight: 700;
}


@mixin heading($hnum){
	font-family: $serif;
	font-weight: 400;
	margin-top: 1.5rem;
	margin-bottom: 0.8rem;
	line-height: 1.5rem;

	@if $hnum == 'h1'{
		font-size: 4.242rem;
		line-height: 7rem;
		margin-top: 3rem;
	}

	@if $hnum == 'h2'{
		font-size: 3.2rem;
		line-height: 5rem;
		margin-top: 3rem;
	}

	@if $hnum == 'h3'{
		font-size: 2rem;
		font-size: 2.121rem;
		line-height: 3rem;
		margin-top: 3rem;
	}

	@if $hnum == 'h4'{
		font-size: 1.414rem;
	}

	@if $hnum == 'h5'{
		font-size: 0.707rem;
		font-family: $sans;
	}

	@if $hnum == 'h6'{
		font-size: 0.4713333333333333rem;
		font-family: $sans;
		font-weight: 700;
	}
}

/* Tables */

table {
	margin-top: 1.5rem;
	border-spacing: 0px;
	border-collapse: collapse;
}

table td,
table th {
	padding: 0;
	line-height: 33px;
}


/* Code blocks */

code {
	vertical-align: bottom;
}


/* ul and ol */

ul {
	padding: 0;
	margin: 0;
	text-align: left;
}

.text--blue {
	color: $cda-blue;
}

.text--red {
	color: $cda_red;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.small {
	font-size: 85%;
}


.ol-styled {
	li {
		padding-top: .6rem;
		counter-increment: step-counter;
		&:before {
			content: counter(step-counter);
		}
	}
}

.ul-icons {
	li {
		&:before {
			font: {
				family: FontAwesome;
				size: 2rem;
			}
			position: absolute;
		}
		&.icon-location:before {
			content: "\f041";
		}
		&.icon-hours:before {
			content: "\f017";
		}
		&.icon-direction:before {
			content: "\f14e";
		}
		&.icon-contact:before {
			content: "\f1fa";
		}
	}
	address {
		font-style: normal;
	}
}
.ul--checkmark{
	li{
		padding-top: 1rem;
		&:before {
			font-size: 2rem;
			position: absolute;
			@include icon('fa-check');
		}	
	}
}
.block-lg {
	@media screen and (min-width: 1200px) {
		display: block;
	}
}
.ul--split{
	max-width: 64rem;
	display: block;
	margin: auto;
	@include clearfix;
	li{
		float: left;
		width: 50%;
	}
}